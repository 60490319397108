<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :leftFields="leftFields"
      :rightFields="rightFields"
      v-model="modelData"
      :updatable="false"
      :deletable="false"
      :customBtns="_titleBarCustomBtns"
      titleKey="name"
      @title-bar-custom-btn-click="$_titleBarCustomBtnClick($event)"
    ></WsReadSection>
    <WsState
      v-if="_extendDisplayCheck"
      type="select"
      :items="_extendItems"
      v-model="state.extend"
    >
    </WsState>
    <WsCrud
      v-if="state.extend==='order-record'"
      class="mt-40"
      :modelName="orderRecord.modelName"
      :label="orderRecord.label"
      :fields="orderRecord.fields"
      :showFields="orderRecord.showFields"
      :params="_orderRecordParams"
      :creatable="false"
      :inRowBtnRead="false"
      :inRowBtnUpdate="false"
      :inRowBtnDelete="false"
      :showExpand="false"
      :expandable="false"
      :customTableActions="customTableActions"
      @read="$_onRead($event)"
    ></WsCrud>
    <div v-else-if="state.extend==='bonus-points'">
      <WsReadSection
        :id="_id"
        :modelName="modelName"
        :fields="fields"
        :leftFields="['bonus_points']"
        :rightFields="[]"
        v-model="modelData"
        :updatable="false"
        :deletable="false"
        :showBreadcrumbs="false"
        title="紅利回饋"
        titleKey="id"
      ></WsReadSection>
      <WsCrud
        class="mt-40"
        :modelName="feedRecord.modelName"
        :label="feedRecord.label"
        :fields="feedRecord.fields"
        :showFields="feedRecord.showFields"
        :params="_feedRecordParams"
        :creatable="false"
        :inRowBtnRead="false"
        :inRowBtnUpdate="false"
        :inRowBtnDelete="false"
        :showExpand="false"
        :expandable="false"
        :customTableActions="customTableActions"
        @read="$_onRead($event)"
      ></WsCrud>
    </div>

  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/user";
import subModel from "@/__vue2stone_cms/models/shop_order";
export default {
  metaInfo() {
    return {
      title: "訂單列表",
    };
  },
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    subfields: subModel.fields,
    leftFields: [
      "id",
      "customer_id",
      "name",
      "gender",
      "status",
      "email",
      "tel",
      "birthday",
      "service_plans",
      "invite_no",
    ],
    rightFields: [],
    modelData: null,
    state: {
      extend: 0,
    },
    showFields: [
      "no",
      "created_at",
      "status",
      "pay_status",
      "products_price",
      "invoice_number",
    ],
    customTableActions: [
      {
        icon: "icon-ws-outline-eye-open",
        emit: "read",
        tooltip: "閱讀",
      },
    ],
    orderRecord: {
      modelName: subModel.modelName,
      label: subModel.label,
      fields: subModel.fields,
      showFields: [
        "no",
        "created_at",
        "status",
        "pay_status",
        "products_price",
        "invoice_number",
      ],
    },
    feedRecord: {
      modelName: subModel.modelName,
      label: subModel.label,
      fields: subModel.fields,
      showFields: [
        "created_at",
        "no",
        "status",
        "products_price",
        "bonus_points",
      ],
    },
  }),

  computed: {
    _extendItems() {
      const _extendItems = [];
      if (this.$config.wsmodule.user.order) {
        _extendItems.push({
          value: "order-record",
          text: "訂單記錄",
        });
      }
      if (this.$config.wsmodule.user.bonus_points) {
        _extendItems.push({
          value: "bonus-points",
          text: "回饋紀錄",
        });
      }
      return _extendItems;
    },
    _extendDisplayCheck() {
      if (
        !this.$config.wsmodule.user.order &&
        !this.$config.wsmodule.user.bonus_points
      ) {
        return false;
      } else {
        return true;
      }
    },
    _orderRecordParams() {
      return {
        user: this._id,
      };
    },
    _feedRecordParams() {
      return {
        user: this._id,
      };
    },
    _id() {
      return this.$route.params.id;
    },
    _titleBarCustomBtns() {
      const _titleBarCustomBtns = [];
      if (this.$config.wsmodule.user.reset_password_mail) {
        _titleBarCustomBtns.push({
          label: "寄送修改密碼信",
          event: "send-reset-password",
          icon: {
            left: true,
          },
        });
      }
      if (this.$config.wsmodule.user.is_bad) {
        _titleBarCustomBtns.push({
          label: "加入黑名單",
          event: "add-to-is-bad",
          icon: {
            left: true,
          },
        });
      }
      if (this.$config.wsmodule.user.export) {
        _titleBarCustomBtns.push({
          label: "匯出會員資料",
          event: "export-user-info",
          icon: {
            left: true,
          },
        });
      }
      return _titleBarCustomBtns;
    },
  },
  methods: {
    $_stateExtendSet() {
      if (this._extendItems.length) {
        this.state.extend = this._extendItems[0].value;
      }
    },
    $_onRead($event) {
      this.$router.push(`/shop_order/${$event.item.id}`);
    },
  },
  mounted() {
    this.$_stateExtendSet();
  },
};
</script>

<style>
</style>